import { DASHBOARD_URL } from "@bay1/ui/urlHelper";
import { useContext } from "react";
import type { PropsWithChildren } from "react";
import { CommonAppContext } from "@bay1/data";

const SignedIn = ({
  children,
}: Readonly<PropsWithChildren<unknown>>): JSX.Element => {
  const { user, loading } = useContext(CommonAppContext);

  if (loading) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  if (!user) {
    // eslint-disable-next-line fp/no-mutation
    window.location.href = `${DASHBOARD_URL}/auth/signin?RedirectURL=${encodeURI(
      window.location.href,
    )}`;

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  if (user.hasDocsAccess ?? false) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  // eslint-disable-next-line fp/no-mutation
  window.location.href = `${DASHBOARD_URL}/comingSoon`;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default SignedIn;
