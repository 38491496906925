import React from "react";
import { Transition } from "@headlessui/react";
import Link from "next/link";

interface HomeModalProperties {
  headline: string;
  message: string;
  isVisible: boolean;
}

const HomeModalComponent = ({
  headline,
  isVisible,
  message,
}: Readonly<HomeModalProperties>): JSX.Element =>
  isVisible ? (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          enter="east-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={isVisible}
        >
          <div aria-hidden="true" className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </div>
          {/* eslint-disable-next-line max-len */}
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            aria-hidden="true"
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
          >
            &nbsp;
          </span>
          <div
            aria-labelledby="modal-headline"
            aria-modal="true"
            className="inline-block align-bottom bg-white rounded-highnote px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
            role="dialog"
          >
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line @shopify/jsx-prefer-fragment-wrappers */}
            <div>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-black">
                {/* Heroicon name: check */}
                <svg
                  aria-hidden="true"
                  className="h-6 w-6 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 13l4 4L19 7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg leading-6 font-display"
                  id="modal-headline"
                >
                  {headline}
                </h3>
                <div className="mt-2">
                  <p className="text-sm">{message}</p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <Link href="/">
                <a>
                  <button
                    className="inline-flex justify-center w-full button button-black"
                    type="button"
                  >
                    Back home
                  </button>
                </a>
              </Link>
            </div>
          </div>
          {/* </Transition> */}
        </Transition>
      </div>
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );

export default HomeModalComponent;
