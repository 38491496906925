import type { DeepReadonly } from "ts-essentials";
import React from "react";
import GlobalStyle from "@bay1/ui";
import "../styles.css";
import "../graphiql-overrides.css";
import "@bay1/ui/styles.css";
import "@bay1/ui/spaceMono.css";
import "@bay1/ui/HelveticaNow.css";
import type { AppProps } from "next/app";
import { init } from "@sentry/node";
import Head from "next/head";
import { useRouter } from "next/router";
import { CommonAppContextProvider } from "@bay1/data";

import SignedIn from "../components/Docs/SignedIn";

if (process.env.NEXT_PUBLIC_SENTRY_DSN ?? "") {
  init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

    ignoreErrors: [
      "Can't find variable: instantSearchSDKJSBridgeClearHighlight",
      "ReferenceError: Can't find variable: article",
      "ReferenceError: Can't find variable: pktAnnotationHighlighter",
      "Non-Error promise rejection captured with value: Object Not Found Matching Id:",
    ],
  });
}

function isRestricted(): boolean {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter();

  return (
    router.pathname.startsWith("/docs") ||
    router.pathname.startsWith("/changelog") ||
    router.pathname.startsWith("/support")
  );
}

const Home = ({
  Component,
  pageProps,
  err,
}: DeepReadonly<AppProps & { err: unknown }>): JSX.Element => {
  const router = useRouter();

  return (
    <>
      <Head>
        <script
          data-site={process.env.NEXT_PUBLIC_FATHOM_SITE_ID}
          defer
          src="https://cdn.usefathom.com/script.js"
        />
        <link href="/favicon.svg" rel="shortcut icon" />
      </Head>
      <GlobalStyle />
      {isRestricted() ? (
        <CommonAppContextProvider router={router}>
          <SignedIn>
            <Component {...pageProps} err={err} />
          </SignedIn>
        </CommonAppContextProvider>
      ) : (
        <CommonAppContextProvider router={router}>
          <Component {...pageProps} err={err} />
        </CommonAppContextProvider>
      )}
    </>
  );
};

export default Home;
